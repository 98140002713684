import React, { useState, useEffect } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { useNavigate } from 'react-router-dom';
import ReactCountryFlag from "react-country-flag"
import randomColor from 'randomcolor';
import NavbarCrew from '../components/layouts/NavbarCrew';
import Footer from '../components/layouts/Footer';
import Scripts from '../components/layouts/Scripts';
import { Container, Row, Col } from 'react-bootstrap';
import { getAllVerifiedJobListFilter, companyFollowed, companyUnFollowed, 
        getAllFeaturedCompany, getUserById
      } from '../components/apiService';
import { getLocalItem, getLocalJsonItem } from '../components/tokenManager';
import JobCard from '../components/layouts/JobCards';
import CrewJobCard from '../components/layouts/CrewJobCards';
import { apiRoutes, limitJobPerPageSetting } from '../config/apiConfig';
import { useParams } from 'react-router-dom';
import EmployerReferalJobCards from '../components/layouts/EmployerReferalJobCards';
import EmployerJobCards from '../components/layouts/EmployerJobCards';

const CompanyJobsScriptCss = () => {
  return (
    <>
      <link rel="stylesheet" href="/static/crew/assets/bootstrap/css/bootstrap.min.css" />
      <link rel="stylesheet" href="/static/crew/assets/css/commoncss.css" />
      <link rel="stylesheet" href="/static/crew/assets/css/home.css" />
      <link rel="stylesheet" href="/static/crew/assets/css/crew_landing.css" />

    </>
  );
};

const CompanyJobsScript = () => {
  return (
    <>
      <script src="/static/crew/assets/js/bold-and-bright.js" ></script>
    </>
  );
};

const CompanyJobs = () => {
  const { empID } = useParams();
  const [companyCurrentId, setCompanyCurrentId] = useState(empID);
  const userToken = getLocalItem("accessToken"); 
  const userData =  getLocalJsonItem("userData");
  const [companyUserData, setCompanyUserData] = useState([]);

  const navigate = useNavigate();
  const handleCompanyClick = (companyId) => {
    setCompanyCurrentId(companyId);
    navigate(`${apiRoutes.company_jobs}${companyId}`);
  };

  // Get Job List Starts
  const [jobList, setJobList] = useState([]);
  const [nextPageUrl, setNextPageUrl] = useState(null);
  const [prevPageUrl, setPrevPageUrl] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(false);

  const [limit, setLimit] = useState(3);

  // console.log("jobList", jobList);
  // Get Job List Ends

  // Featured Company List Starts
  const [featuredCompanies, setFeaturedCompanies] = useState([]);

  useEffect(() => {
    const fetchFeaturedCompanies = async () => {
      try {
        const data = await getAllFeaturedCompany(userToken);
        setFeaturedCompanies(data); 
      } catch (error) {
        console.error('Error fetching featured companies:', error);
      }
    };

    fetchFeaturedCompanies(); 
  }, [userToken]); 
  // Featured Company List Ends

  // Follow Toggle Starts
  const [followingStatus, setFollowingStatus] = useState({});
  React.useEffect(() => {
    const initialFollowingStatus = {};
    jobList.forEach(job => {
      initialFollowingStatus[job.posted_by.id] = job.posted_by.userfollow_status;
    });
    setFollowingStatus(initialFollowingStatus);
  }, [jobList]);


  const handleFollowToggle = (job) => {
    const isCurrentlyFollowing = followingStatus[job.posted_by.id];
  
    if (isCurrentlyFollowing) {
      // Call unfollow API
      companyUnFollowed(job.posted_by.id, userData.id, userToken)
        .then(response => {
          setFollowingStatus((prevStatus) => ({
            ...prevStatus,
            [job.posted_by.id]: false
          }));
        })
        .catch(error => {
          console.error('Error unfollowing User:', error);
        });
    } else {
      // Call follow API
      const formData = new FormData();
      formData.append("user_id", job.posted_by.id);
      formData.append("user_followed_by", userData.id);
  
      // console.log("formData", formData);
      companyFollowed(formData, userToken)
        .then(response => {
          setFollowingStatus((prevStatus) => ({
            ...prevStatus,
            [job.posted_by.id]: true
          }));
        })
        .catch(error => {
          console.error('Error following User:', error);
        });
    }
  };
  // Follow Toggle Ends

  useEffect(() => {
    const fetchJobs = async () => {
      setLoading(true);
      try {
        const response = await getUserById(companyCurrentId, userToken);
        const results = response || [];
        setCompanyUserData(results);
      } catch (error) {
        console.error('Error fetching job list:', error);
      } 
    };

    fetchJobs();
  }, [userToken, companyCurrentId]);

  // Generate query string Starts
  // const generateQueryString = (companyCurrentId, pageData, ) => {
  //   const companyParams = `emp_id=${companyCurrentId}`;
  //   const paginationParams = `limit=${limit}&page=${pageData}&verified_jobs=${verified_jobs}&active=${active}`;
  //   return `${companyParams}&${paginationParams}`;
  // };

  const generateQueryString = ( companyCurrentId, pageData, verified_jobs = 1, active = 1, limit = limitJobPerPageSetting.limit) => {
    const companyParams = `emp_id=${encodeURIComponent(companyCurrentId)}`;
    const paginationParams = `limit=${limit}&page=${encodeURIComponent(pageData)}&verified_jobs=${verified_jobs}&active=${active}`;
    return `${companyParams}&${paginationParams}`;
  };

  useEffect(() => {
    const fetchJobs = async () => {
      setLoading(true);
      try {
        const queryString = generateQueryString(companyCurrentId, currentPage);
        const response = await getAllVerifiedJobListFilter(queryString, userToken);
        
        const results = response.results || [];
        setJobList(results);
        setNextPageUrl(response.next || null);
        setPrevPageUrl(response.previous || null);
        
        const count = response.count || 0;
        setTotalPages(count > 0 ? Math.ceil(count / limit) : 1);
      } catch (error) {
        console.error('Error fetching job list:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchJobs();
  }, [userToken, companyCurrentId]);
  // Generate query string Ends

  // Pagination Starts
  const handlePageChange = async (pageNum) => {
    setLoading(true);
    try {
      const queryString = generateQueryString(companyCurrentId, pageNum);
      const response = await getAllVerifiedJobListFilter(queryString, userToken);
      setJobList(response.results || []);
      setNextPageUrl(response.next || null);
      setPrevPageUrl(response.previous || null);
      setCurrentPage(pageNum >= totalPages ? totalPages : pageNum); 
    } catch (error) {
      console.error('Error fetching page of jobs:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const initialFollowingStatus = {};
    jobList.forEach(job => {
      initialFollowingStatus[job.posted_by.id] = job.posted_by.userfollow_status;
    });
    setFollowingStatus(initialFollowingStatus);
  }, [jobList]);

  const renderPagination = () => {
    const pages = [];
    for (let i = 1; i <= totalPages; i++) {
      pages.push(
        <button
          key={i}
          disabled={i === currentPage}
          className="btn pagination-digits-button"
          onClick={() => handlePageChange(i)}
        >
          {i}
        </button>
      );
    }
    return pages;
  };
  // Pagination Ends
 
  return (
    <>
        <CompanyJobsScriptCss />
        <NavbarCrew />

            <div className="header-text-1">
              <div className="container">
                <div className="company-info-container">
                  <div className="company-info-img-container">
                    <h1 className="company-info-img-type" style={{ background: randomColor() }}>
                      {companyUserData.company_name && companyUserData.company_name.charAt(0)}
                    </h1>
                  </div>
                  <div className="company-info-detail-container">
                    <div className="company-info-detail-name-container">
                      <h1 className="company-info-detail-name">
                        {companyUserData.company_name}
                      </h1>
                      <LazyLoadImage src="/static/crew/assets/img/icons/Chield_check_fill.svg" alt="Verified Icon" />
                    </div>
                    <div className='company-flag-name'>
                      <ReactCountryFlag 
                        countryCode={companyUserData.country && companyUserData.country.country_code} 
                        svg />
                      <p className="company-info-country">
                        {companyUserData.country && companyUserData.country.country_name}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <>
              <div className="all-jobs-div">
                <Container className="all-jobs-container">
                    <Row>
                        <Col md={6} className="all-jobs-col-1">
                            <div className="jobs-card-container">
                              {userData && userData.user_type_key === 2 ?
                                <>
                                {jobList && jobList.map((job, index) => (
                                  job.posted_by.user_type_key === 2 ? (
                                      <CrewJobCard
                                          key={job.id || index}
                                          job={job}
                                          isFollowing={followingStatus[job.posted_by.id]}
                                          onFollowToggle={() => handleFollowToggle(job)}
                                          userData={userData}
                                          userToken={userToken}
                                      />
                                  ) : (
                                      <JobCard
                                          key={job.id || index}
                                          job={job}
                                          isFollowing={followingStatus[job.posted_by.id]}
                                          onFollowToggle={() => handleFollowToggle(job)}
                                          userData={userData}
                                          userToken={userToken}
                                      />
                                  )
                                ))}
                                </>
                                :
                                <>
                                {jobList && jobList.map((job, index) => (
                                    job.posted_by.user_type_key === 2 ? (
                                        <EmployerReferalJobCards
                                            key={job.id || index}
                                            job={job}
                                            isFollowing={followingStatus[job.posted_by.id]}
                                            onFollowToggle={() => handleFollowToggle(job)}
                                            userData={userData}
                                            userToken={userToken}
                                        />
                                    ) : (
                                        <EmployerJobCards
                                            key={job.id || index}
                                            job={job}
                                            isFollowing={followingStatus[job.posted_by.id]}
                                            onFollowToggle={() => handleFollowToggle(job)}
                                            userData={userData}
                                            userToken={userToken}
                                        />
                                    )
                                ))}
                                </>
                              }

                              {jobList && jobList.length ===0 && (
                                <div className="empty-data-container">
                                    <LazyLoadImage className="empty-data-image" src="/static/crew/assets/img/images/empty_img.svg" alt="Empty" />
                                    <h1 className="empty-tab-text">No Data Available!</h1>
                                </div>
                              )}
                            </div>
                        </Col>
                        <Col md={6} className="all-jobs-col-2">
                          <div className="other-column-container">
                            <div className="filter-container">
                              <div className="filter-col-header">
                                <LazyLoadImage src="/static/crew/assets/img/icons/Company_black.svg" alt="Filter Icon" />
                                <h1 className="heading-filter">Featured Companies</h1>
                              </div>
                              <div className="featured-company-names">
                                {featuredCompanies.map((company, index) => (
                                  <div key={index} onClick={() => handleCompanyClick(company.id)}>
                                    <h1 className="feature-company-name">{company.company_name}</h1>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </div>
                        </Col>
                    </Row>
                </Container>
              </div>
              {jobList && jobList.length !== 0 && (
                <div className="pagination-container">
                  <button className="btn pagination-prev-button"  
                    disabled={!prevPageUrl}
                    onClick={() => handlePageChange(currentPage - 1)}>
                    <LazyLoadImage  src="/static/crew/assets/img/arrows/arrow_right_blue.svg"  />
                  </button>
                  {renderPagination()}
                  <button className="btn pagination-next-button" 
                    disabled={!nextPageUrl}
                    onClick={() => handlePageChange(currentPage + 1)}>
                    <LazyLoadImage  src="/static/crew/assets/img/arrows/arrow_right_blue.svg"  />
                  </button>
                </div>
              )}
            </>

        <Footer />
        <Scripts />
        <CompanyJobsScript />
    </>
  );
};

export default CompanyJobs