import React, { useState, useEffect } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import Footer from '../components/layouts/Footer';
import Scripts from '../components/layouts/Scripts';
import { Container, Row, Col, Dropdown, InputGroup, FormControl } from 'react-bootstrap';
import { getVesselList, getRankList, get_all_verified_job_list_no_auth } from '../components/apiService';
import NavbarHome from '../components/layouts/NavbarHome';
import CrewJobCardNoAuth from '../components/layouts/CrewJobCardsNoAuth';
import JobCardNoAuth from '../components/layouts/JobCardsNoAuth';
import { limitJobPerPageSetting } from '../config/apiConfig';

const CrewFindJobsNoAuthScriptCss = () => {
  return (
    <>
      <link rel="stylesheet" href="/static/crew/assets/bootstrap/css/bootstrap.min.css" />
      <link rel="stylesheet" href="/static/crew/assets/css/commoncss.css" />
      <link rel="stylesheet" href="/static/crew/assets/css/home.css" />
      <link rel="stylesheet" href="/static/crew/assets/css/crew_landing.css" />
    </>
  );
};

const CrewFindJobsNoAuthScript = () => {
  return (
    <>
      <script src="/static/crew/assets/js/bold-and-bright.js"></script>
    </>
  );
};

const CrewFindJobsNoAuth = () => {
  const [activeTab, setActiveTab] = useState('All Jobs');
  const [jobList, setJobList] = useState([]);
  const [nextPageUrl, setNextPageUrl] = useState(null);
  const [prevPageUrl, setPrevPageUrl] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(false);
  const [searchVesselQuery, setSearchVesselQuery] = useState('');
  const [selectedVesselItems, setSelectedVesselItems] = useState([]);
  const [vesselItems, setVesselItems] = useState({});
  const [filteredVesselItems, setFilteredVesselItems] = useState({});
  const [searchRankQuery, setSearchRankQuery] = useState('');
  const [selectedRankItems, setSelectedRankItems] = useState([]);
  const [rankItems, setRankItems] = useState([]);
  const [filteredRankItems, setFilteredRankItems] = useState([]);
  const [limit, setLimit] = useState(3);
  const [jsonLdData, setJsonLdData] = useState(null); // State to store JSON-LD data

  // Fetch vessel list
  useEffect(() => {
    const fetchVesselList = async () => {
      const vessels = await getVesselList();
      setVesselItems(vessels);
      setFilteredVesselItems(vessels);
    };
    fetchVesselList();
  }, []);

  // Filter vessel items
  useEffect(() => {
    const newFilteredVesselItems = {};
    Object.keys(vesselItems).forEach(parentKey => {
      const filteredChildren = vesselItems[parentKey].filter(childItem =>
        childItem.name.toLowerCase().includes(searchVesselQuery.toLowerCase())
      );
      if (filteredChildren.length > 0) {
        newFilteredVesselItems[parentKey] = filteredChildren;
      }
    });
    setFilteredVesselItems(newFilteredVesselItems);
  }, [searchVesselQuery, vesselItems]);

  // Handle vessel selection
  const handleVesselSelect = (selectedItem) => {
    if (!selectedVesselItems.some(item => item.id === selectedItem.id)) {
      setSelectedVesselItems([...selectedVesselItems, selectedItem]);
    }
    setSearchVesselQuery('');
  };

  // Handle vessel removal
  const handleVesselRemove = (itemId) => {
    setSelectedVesselItems(selectedVesselItems.filter(item => item.id !== itemId));
  };

  // Fetch rank list
  useEffect(() => {
    const fetchRankList = async () => {
      const ranks = await getRankList();
      setRankItems(ranks);
      setFilteredRankItems(ranks);
    };
    fetchRankList();
  }, []);

  // Filter rank items
  useEffect(() => {
    const newFilteredRankItems = rankItems.filter(rankItem =>
      rankItem.name.toLowerCase().includes(searchRankQuery.toLowerCase())
    );
    setFilteredRankItems(newFilteredRankItems);
  }, [searchRankQuery, rankItems]);

  // Handle rank selection
  const handleRankSelect = (selectedItem) => {
    if (!selectedRankItems.some(item => item.id === selectedItem.id)) {
      setSelectedRankItems([...selectedRankItems, selectedItem]);
    }
    setSearchRankQuery('');
  };

  // Handle rank removal
  const handleRankRemove = (itemId) => {
    setSelectedRankItems(selectedRankItems.filter(item => item.id !== itemId));
  };

  // Generate query string
  // const generateQueryString = (vesselItems, rankItems, pageData) => {
  //   const vesselParams = vesselItems.map(item => `vessel_id=${item.id}`).join('&');
  //   const rankParams = rankItems.map(item => `rank=${item.id}`).join('&');
  //   const paginationParams = `limit=${limit}&page=${pageData}`;
  //   return `${vesselParams}&${rankParams}&${paginationParams}`;
  // };

  const generateQueryString = ( vesselItems, rankItems, pageData, limit = limitJobPerPageSetting.limit, verified_jobs = 1, active = 1) => {
    const vesselParams = vesselItems.map(item => `vessel_id=${encodeURIComponent(item.id)}`).join('&');
    const rankParams = rankItems.map(item => `rank=${encodeURIComponent(item.id)}`).join('&');
    const paginationParams = `limit=${limit}&page=${encodeURIComponent(pageData)}&verified_jobs=${verified_jobs}&active=${active}`;
    const queryParams = [vesselParams, rankParams, paginationParams]
      .filter(param => param)
      .join('&');
    return queryParams;
  };


  // Fetch jobs and generate JSON-LD data
  useEffect(() => {
    const fetchJobs = async () => {
      setLoading(true);
      try {
        const queryString = generateQueryString(selectedVesselItems, selectedRankItems, currentPage);
        const response = await get_all_verified_job_list_no_auth(queryString);

        const results = response.results || [];
        setJobList(results);

        // Generate JSON-LD data
        const jsonLdData = results.map(job => generateJsonLd(job));
        setJsonLdData(jsonLdData); // Store JSON-LD data in state

        setNextPageUrl(response.next || null);
        setPrevPageUrl(response.previous || null);

        const count = response.count || 0;
        setTotalPages(count > 0 ? Math.ceil(count / limit) : 1);
      } catch (error) {
        console.error('Error fetching job list:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchJobs();
  }, [selectedVesselItems, selectedRankItems, currentPage]);

  // Inject JSON-LD script into the <head>
  useEffect(() => {
    if (jsonLdData) {
      // Remove any existing JSON-LD script
      const existingScript = document.querySelector('script[type="application/ld+json"]');
      if (existingScript) {
        document.head.removeChild(existingScript);
      }

      // Create a new script element
      const script = document.createElement('script');
      script.type = 'application/ld+json';
      script.text = JSON.stringify(jsonLdData);

      // Append the script to the <head>
      document.head.appendChild(script);
    }
  }, [jsonLdData]); // Run this effect whenever jsonLdData changes

  const generateJsonLd = (job) => {
    // Parse tentativeJoiningDate if available
    const tentativeJoiningDate = job.tentative_joining ? new Date(job.tentative_joining) : null;

    // Validate tentativeJoiningDate
    const isValidTentativeJoiningDate = tentativeJoiningDate && !isNaN(tentativeJoiningDate.getTime());

    // Parse expiryInDays (default to 30 if not provided or invalid)
    const expiryInDays = parseInt(job.expiry_in_day) || 30;

    // Calculate expiryDate
    let expiryDate;
    if (isValidTentativeJoiningDate) {
      // If tentativeJoiningDate is valid, add expiryInDays to it
      expiryDate = new Date(tentativeJoiningDate);
      expiryDate.setDate(tentativeJoiningDate.getDate() + expiryInDays);
    } else {
      // If tentativeJoiningDate is invalid, calculate expiryDate as 3 days from today
      expiryDate = new Date(Date.now() + 3 * 24 * 60 * 60 * 1000); // 3 days from today
    }

    // Validate expiryDate
    const isValidExpiryDate = expiryDate && !isNaN(expiryDate.getTime());

    return {
      "@context": "https://joinmyship.com/",
      "@type": "JobPosting",
      "title": job.Job_Rank_wages[0]?.rank_number.name || "Unknown Job Title",
      "description": job.description || "No job description available.",
      "datePosted": isValidTentativeJoiningDate ? tentativeJoiningDate.toISOString() : new Date().toISOString(),
      "validThrough": isValidExpiryDate ? expiryDate.toISOString() : new Date(Date.now() + 3 * 24 * 60 * 60 * 1000).toISOString(),
      "employmentType": "CONTRACTOR",
      "hiringOrganization": {
        "@type": "Organization",
        "name": job.posted_by?.company_name || "Join My Ship Company",
        "sameAs": job.posted_by?.website || "https://joinmyship.com",
        "logo": job.posted_by?.profilePic || "https://joinmyship.com/static/crew/assets/img/brands/Favicon.png"
      },
      "jobLocation": {
        "@type": "Place",
        "address": {
          "@type": "PostalAddress",
          "streetAddress": job.location?.street || "N/A",
          "addressLocality": job.location?.city || "N/A",
          "addressRegion": job.location?.state || "N/A",
          "postalCode": job.location?.postal_code || "N/A",
          "addressCountry": job.location?.country || "IN"
        }
      },
      "baseSalary": {
        "@type": "MonetaryAmount",
        "currency": "INR",
        "value": {
          "@type": "QuantitativeValue",
          "value": job.Job_Rank_wages[0]?.wages || "0",
          "unitText": "MONTH"
        }
      },
      "identifier": {
        "@type": "PropertyValue",
        "name": job.posted_by?.company_name || "Join My Ship Company",
        "value": job.id?.toString() || "unknown-id"
      },
      "jobLocationType": "TELECOMMUTE",
      "applicantLocationRequirements": {
        "@type": "Country",
        "name": "India"
      }
    };
  };



  // Pagination handlers
  const handlePageChange = async (pageNum) => {
    setLoading(true);
    try {
      const queryString = generateQueryString(selectedVesselItems, selectedRankItems, pageNum);
      const response = await get_all_verified_job_list_no_auth(queryString);
      setJobList(response.results || []);
      setNextPageUrl(response.next || null);
      setPrevPageUrl(response.previous || null);
      setCurrentPage(pageNum >= totalPages ? totalPages : pageNum);
    } catch (error) {
      console.error('Error fetching page of jobs:', error);
    } finally {
      setLoading(false);
    }
  };

  const renderPagination = () => {
    const pages = [];
    for (let i = 1; i <= totalPages; i++) {
      pages.push(
        <button
          key={i}
          disabled={i === currentPage}
          className="btn pagination-digits-button"
          onClick={() => handlePageChange(i)}
        >
          {i}
        </button>
      );
    }
    return pages;
  };

  return (
    <>
        <CrewFindJobsNoAuthScriptCss />
        <NavbarHome />

          <div className="header-text-1">
            <Container>
              <h1 className="landing-header">Available Jobs For You</h1>
            </Container>
            <Container className="job-tabs">
              <h1
                className={`tab-header ${activeTab === 'All Jobs' ? 'active' : ''}`}
                onClick={() => setActiveTab('All Jobs')}
              >
                All Jobs
              </h1>
            </Container>

          </div>

          {activeTab === 'All Jobs' && (
            <>
                <div className="all-jobs-div">
                  <Container className="all-jobs-container">
                      <Row>
                          <Col md={6} className="all-jobs-col-1">
                              <div className="jobs-card-container">
                                {jobList && jobList.map((job, index) => (
                                    job.posted_by.user_type_key === 2 ? (
                                        <CrewJobCardNoAuth
                                            key={job.id || index}
                                            job={job}
                                        />
                                    ) : (
                                        <JobCardNoAuth
                                            key={job.id || index}
                                            job={job}
                                        />
                                    )
                                ))}

                                {jobList && jobList.length ===0 && (
                                  <div className="empty-data-container">
                                      <LazyLoadImage className="empty-data-image" src="/static/crew/assets/img/images/empty_img.svg" alt="Empty" />
                                      <h1 className="empty-tab-text">No Data Available!</h1>
                                  </div>
                                )}
                              </div>
                          </Col>
                          <Col md={6} className="all-jobs-col-2">
                            <div className="other-column-container">
                              <div className="filter-container">
                                <div className="filter-col-header">
                                  <LazyLoadImage src="/static/crew/assets/img/icons/Filter.svg" alt="Filter Icon" />
                                  <h1 className="heading-filter">Filters</h1>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
                                  <p className="filter-rank">Rank</p>
                                  <Dropdown className='rank-search-dropdown vessel-search-dropdown'>
                                      <Dropdown.Toggle className="btn dropdown-toggle dropdown-form-button rank-search vessel-search" variant="none" id="rank-dropdown">
                                        <InputGroup className="mb-3 search-rank-input">
                                          <FormControl
                                            className="search-form-input"
                                            placeholder='Select Ranks'
                                            aria-label="Search"
                                            aria-describedby="basic-addon2"
                                            value={searchRankQuery}
                                            onChange={(e) => setSearchRankQuery(e.target.value)}
                                            required={selectedRankItems.length === 0}
                                          />
                                        </InputGroup>
                                      </Dropdown.Toggle>
                                      <Dropdown.Menu>
                                        {filteredRankItems.map((rankItem, index) => (
                                          <Dropdown.Item key={index} onClick={() => handleRankSelect(rankItem)}>
                                            {rankItem.name}
                                          </Dropdown.Item>
                                        ))}
                                      </Dropdown.Menu>
                                    </Dropdown>

                                    {selectedRankItems.length > 0 && (
                                      <div className="selected-rank-container">
                                        {selectedRankItems.map((item) => (
                                          <div key={item.id} className="selected-rank">
                                            <p className="selected-rank-text">{item.name}</p>
                                            <LazyLoadImage
                                              src="/static/crew/assets/img/icons/icn_close.svg"
                                              alt="Close Icon"
                                              onClick={() => handleRankRemove(item.id)}
                                            />
                                          </div>
                                        ))}
                                      </div>
                                    )}
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
                                  <p className="filter-rank">Vessel Type</p>
                                  <Dropdown className='vessel-search-dropdown'>
                                    <Dropdown.Toggle className="btn dropdown-toggle dropdown-form-button vessel-search" variant="none" id="vessel-dropdown">
                                      <InputGroup className="mb-3 search-rank-input">
                                        <FormControl
                                          className="search-form-input"
                                          placeholder= 'Select Vessels'
                                          aria-label="Search"
                                          aria-describedby="basic-addon2"
                                          value={searchVesselQuery}
                                          onChange={(e) => setSearchVesselQuery(e.target.value)}
                                          required={selectedVesselItems.length === 0}
                                        />
                                      </InputGroup>
                                    </Dropdown.Toggle>
                                      <Dropdown.Menu>
                                        {Object.keys(filteredVesselItems).map((parentKey, parentIndex) => (
                                          <React.Fragment key={parentIndex}>
                                            <Dropdown.Item disabled className='vessle-type-parent'>
                                              <b>{parentKey}</b>
                                            </Dropdown.Item>
                                            {filteredVesselItems[parentKey].map((childItem, childIndex) => (
                                              <Dropdown.Item key={childIndex} onClick={() => handleVesselSelect(childItem)}>
                                                {childItem.name}
                                              </Dropdown.Item>
                                            ))}
                                          </React.Fragment>
                                        ))}
                                      </Dropdown.Menu>
                                    </Dropdown>
                                    {selectedVesselItems.length > 0 && (
                                      <div className="selected-rank-container">
                                        {selectedVesselItems.map((item) => (
                                          <div key={item.id} className="selected-rank">
                                            <p className="selected-rank-text">{item.name}</p>
                                            <LazyLoadImage
                                              src="/static/crew/assets/img/icons/icn_close.svg"
                                              alt="Close Icon"
                                              onClick={() => handleVesselRemove(item.id)}
                                            />
                                          </div>
                                        ))}
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </Col>
                      </Row>
                  </Container>
              </div>

              {jobList && jobList.length !== 0 && (
                <div className="pagination-container">
                  <button className="btn pagination-prev-button"  
                    disabled={!prevPageUrl}
                    onClick={() => handlePageChange(currentPage - 1)}>
                    <LazyLoadImage  src="/static/crew/assets/img/arrows/arrow_right_blue.svg"  />
                  </button>
                  {renderPagination()}
                  <button className="btn pagination-next-button" 
                    disabled={!nextPageUrl}
                    onClick={() => handlePageChange(currentPage + 1)}>
                    <LazyLoadImage  src="/static/crew/assets/img/arrows/arrow_right_blue.svg"  />
                  </button>
                </div>
              )}

            </>
          )}

        <Footer />
        <Scripts />
        <CrewFindJobsNoAuthScript />
    </>
  );
};

export default CrewFindJobsNoAuth