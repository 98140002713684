import React, { useState, useEffect } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { useNavigate } from 'react-router-dom';
import NavbarCrew from '../components/layouts/NavbarCrew';
import Footer from '../components/layouts/Footer';
import Scripts from '../components/layouts/Scripts';
import { Container, Row, Col, Dropdown, InputGroup, FormControl } from 'react-bootstrap';
import { getAllVerifiedJobListFilter, getVesselList, 
        getRankList, companyFollowed, companyUnFollowed, getAllFeaturedCompany
      } from '../components/apiService';
import { getLocalItem, getLocalJsonItem } from '../components/tokenManager';
import { apiRoutes, limitJobPerPageSetting } from '../config/apiConfig';
import EmployerJobCards from '../components/layouts/EmployerJobCards';
import EmployerReferalJobCards from '../components/layouts/EmployerReferalJobCards';


const EmployerFindJobsScriptCss = () => {
  return (
    <>
      <link rel="stylesheet" href="/static/crew/assets/bootstrap/css/bootstrap.min.css" />
      <link rel="stylesheet" href="/static/crew/assets/css/commoncss.css" />
      <link rel="stylesheet" href="/static/crew/assets/css/home.css" />
      <link rel="stylesheet" href="/static/crew/assets/css/crew_landing.css" />

    </>
  );
};

const EmployerFindJobsScript = () => {
  return (
    <>
      <script src="/static/crew/assets/js/bold-and-bright.js" ></script>
    </>
  );
};

const EmployerFindJobs = () => {
  const [activeTab, setActiveTab] = useState('All Jobs');
  const userToken = getLocalItem("accessToken"); 
  const userData =  getLocalJsonItem("userData");

  // Get Job List Starts
  const [jobList, setJobList] = useState([]);
  const [nextPageUrl, setNextPageUrl] = useState(null);
  const [prevPageUrl, setPrevPageUrl] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(false);

  const [searchVesselQuery, setSearchVesselQuery] = useState('');
  const [selectedVesselItems, setSelectedVesselItems] = useState([]);
  const [vesselItems, setVesselItems] = useState({});
  const [filteredVesselItems, setFilteredVesselItems] = useState({});

  const [searchRankQuery, setSearchRankQuery] = useState('');
  const [selectedRankItems, setSelectedRankItems] = useState([]);
  const [rankItems, setRankItems] = useState([]);
  const [filteredRankItems, setFilteredRankItems] = useState([]);
  
  const [limit, setLimit] = useState(3);

  // Get Job List Ends

  // Vessels Dropdown Starts
  useEffect(() => {
    const fetchVesselList = async () => {
      const vessels = await getVesselList();
      setVesselItems(vessels);
      setFilteredVesselItems(vessels);
    };
    fetchVesselList();
  }, []);

  useEffect(() => {
    const newFilteredVesselItems = {};
    Object.keys(vesselItems).forEach(parentKey => {
      const filteredChildren = vesselItems[parentKey].filter(childItem =>
        childItem.name.toLowerCase().includes(searchVesselQuery.toLowerCase())
      );
      if (filteredChildren.length > 0) {
        newFilteredVesselItems[parentKey] = filteredChildren;
      }
    });
    setFilteredVesselItems(newFilteredVesselItems);
  }, [searchVesselQuery, vesselItems]);

  const handleVesselSelect = (selectedItem) => {
    if (!selectedVesselItems.some(item => item.id === selectedItem.id)) {
      setSelectedVesselItems([...selectedVesselItems, selectedItem]);
    }
    setSearchVesselQuery(''); 
  };

  const handleVesselRemove = (itemId) => {
    setSelectedVesselItems(selectedVesselItems.filter(item => item.id !== itemId));
  };
  // Vessels Dropdown Ends

  // Dropdown Rank Starts

  useEffect(() => {
    const fetchRankList = async () => {
      const ranks = await getRankList();
      setRankItems(ranks);
      setFilteredRankItems(ranks);
    };
    fetchRankList();
  }, []);

  useEffect(() => {
    const newFilteredRankItems = rankItems.filter(rankItem =>
      rankItem.name.toLowerCase().includes(searchRankQuery.toLowerCase())
    );
    setFilteredRankItems(newFilteredRankItems);
  }, [searchRankQuery, rankItems]);

  const handleRankSelect = (selectedItem) => {
    if (!selectedRankItems.some(item => item.id === selectedItem.id)) {
      setSelectedRankItems([...selectedRankItems, selectedItem]);
    }
    setSearchRankQuery(''); 
  };

  const handleRankRemove = (itemId) => {
    setSelectedRankItems(selectedRankItems.filter(item => item.id !== itemId));
  };

  // Dropdown Rank Ends

  // Featured Company List Starts
  const [featuredCompanies, setFeaturedCompanies] = useState([]);

  useEffect(() => {
    const fetchFeaturedCompanies = async () => {
      try {
        const data = await getAllFeaturedCompany(userToken);
        setFeaturedCompanies(data); 
      } catch (error) {
        console.error('Error fetching featured companies:', error);
      }
    };

    fetchFeaturedCompanies(); 
  }, [userToken]); 
  // Featured Company List Ends



  // Follow Toggle Starts
  const [followingStatus, setFollowingStatus] = useState({});
  React.useEffect(() => {
    const initialFollowingStatus = {};
    jobList.forEach(job => {
      initialFollowingStatus[job.posted_by.id] = job.posted_by.userfollow_status;
    });
    setFollowingStatus(initialFollowingStatus);
  }, [jobList]);


  const handleFollowToggle = (job) => {
    const isCurrentlyFollowing = followingStatus[job.posted_by.id];
  
    if (isCurrentlyFollowing) {
      // Call unfollow API
      companyUnFollowed(job.posted_by.id, userData.id, userToken)
        .then(response => {
          setFollowingStatus((prevStatus) => ({
            ...prevStatus,
            [job.posted_by.id]: false
          }));
        })
        .catch(error => {
          console.error('Error unfollowing User:', error);
        });
    } else {
      // Call follow API
      const formData = new FormData();
      formData.append("user_id", job.posted_by.id);
      formData.append("user_followed_by", userData.id);
  
      // console.log("formData", formData);
      companyFollowed(formData, userToken)
        .then(response => {
          setFollowingStatus((prevStatus) => ({
            ...prevStatus,
            [job.posted_by.id]: true
          }));
        })
        .catch(error => {
          console.error('Error following User:', error);
        });
    }
  };
  // Follow Toggle Ends


  // Generate query string Starts
  // const generateQueryString = (vesselItems, rankItems, pageData) => {
  //   const vesselParams = vesselItems.map(item => `vessel_id=${item.id}`).join('&');
  //   const rankParams = rankItems.map(item => `rank=${item.id}`).join('&');
  //   const paginationParams = `limit=${limit}&page=${pageData}`;
  //   return `${vesselParams}&${rankParams}&${paginationParams}`;
  // };

  const generateQueryString = ( vesselItems, rankItems, pageData, limit = limitJobPerPageSetting.limit, verified_jobs = 1, active = 1 ) => {
    const vesselParams = vesselItems.map(item => `vessel_id=${encodeURIComponent(item.id)}`).join('&');
    const rankParams = rankItems.map(item => `rank=${encodeURIComponent(item.id)}`).join('&');
    const paginationParams = `limit=${limit}&page=${encodeURIComponent(pageData)}&verified_jobs=${verified_jobs}&active=${active}`;
    const queryParams = [vesselParams, rankParams, paginationParams]
      .filter(param => param)
      .join('&');

    return queryParams;
  };

  useEffect(() => {
    const fetchJobs = async () => {
      setLoading(true);
      try {
        const queryString = generateQueryString(selectedVesselItems, selectedRankItems, currentPage);
        const response = await getAllVerifiedJobListFilter(queryString, userToken);
        
        const results = response.results || [];
        setJobList(results);
        setNextPageUrl(response.next || null);
        setPrevPageUrl(response.previous || null);
        
        const count = response.count || 0;
        setTotalPages(count > 0 ? Math.ceil(count / limit) : 1);
      } catch (error) {
        console.error('Error fetching job list:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchJobs();
  }, [userToken, selectedVesselItems, selectedRankItems]);
  // Generate query string Ends

  // Pagination Starts
  const handlePageChange = async (pageNum) => {
    setLoading(true);
    try {
      const queryString = generateQueryString(selectedVesselItems, selectedRankItems, pageNum);
      const response = await getAllVerifiedJobListFilter(queryString, userToken);
      setJobList(response.results || []);
      setNextPageUrl(response.next || null);
      setPrevPageUrl(response.previous || null);
      setCurrentPage(pageNum >= totalPages ? totalPages : pageNum); 
    } catch (error) {
      console.error('Error fetching page of jobs:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const initialFollowingStatus = {};
    jobList.forEach(job => {
      initialFollowingStatus[job.posted_by.id] = job.posted_by.userfollow_status;
    });
    setFollowingStatus(initialFollowingStatus);
  }, [jobList]);

  const renderPagination = () => {
    const pages = [];
    for (let i = 1; i <= totalPages; i++) {
      pages.push(
        <button
          key={i}
          disabled={i === currentPage}
          className="btn pagination-digits-button"
          onClick={() => handlePageChange(i)}
        >
          {i}
        </button>
      );
    }
    return pages;
  };
  // Pagination Ends


  const navigate = useNavigate();
  const handleCompanyClick = (companyId) => {
    navigate(`${apiRoutes.company_jobs}${companyId}`);
  };

  return (
    <>
        <EmployerFindJobsScriptCss />
        <NavbarCrew />

          <div className="header-text-1">
            <Container className="landing-header-employer-myjobs">
              <h1 className="landing-header">Available Jobs For You</h1>
            </Container>
          </div>

            <>
                <div className="all-jobs-div">
                  <Container className="all-jobs-container">
                      <Row>
                          <Col md={6} className="all-jobs-col-1">
                              <div className="jobs-card-container">
                                {jobList && jobList.map((job, index) => (
                                    job.posted_by.user_type_key === 2 ? (
                                        <EmployerReferalJobCards
                                            key={job.id || index}
                                            job={job}
                                            isFollowing={followingStatus[job.posted_by.id]}
                                            onFollowToggle={() => handleFollowToggle(job)}
                                            userData={userData}
                                            userToken={userToken}
                                        />
                                    ) : (
                                        <EmployerJobCards
                                            key={job.id || index}
                                            job={job}
                                            isFollowing={followingStatus[job.posted_by.id]}
                                            onFollowToggle={() => handleFollowToggle(job)}
                                            userData={userData}
                                            userToken={userToken}
                                        />
                                    )
                                ))}

                                {jobList && jobList.length ===0 && (
                                  <div className="empty-data-container">
                                      <LazyLoadImage className="empty-data-image" src="/static/crew/assets/img/images/empty_img.svg" alt="Empty" />
                                      <h1 className="empty-tab-text">No Data Available!</h1>
                                  </div>
                                )}
                              </div>
                          </Col>
                          <Col md={6} className="all-jobs-col-2">
                            <div className="other-column-container">
                              <div className="filter-container">
                                <div className="filter-col-header">
                                  <LazyLoadImage src="/static/crew/assets/img/icons/Filter.svg" alt="Filter Icon" />
                                  <h1 className="heading-filter">Filters</h1>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
                                  <p className="filter-rank">Rank</p>
                                  <Dropdown className='rank-search-dropdown vessel-search-dropdown'>
                                      <Dropdown.Toggle className="btn dropdown-toggle dropdown-form-button rank-search vessel-search" variant="none" id="rank-dropdown">
                                        <InputGroup className="mb-3 search-rank-input">
                                          <FormControl
                                            className="search-form-input"
                                            placeholder='Select Ranks'
                                            aria-label="Search"
                                            aria-describedby="basic-addon2"
                                            value={searchRankQuery}
                                            onChange={(e) => setSearchRankQuery(e.target.value)}
                                            required={selectedRankItems.length === 0}
                                          />
                                        </InputGroup>
                                      </Dropdown.Toggle>
                                      <Dropdown.Menu>
                                        {filteredRankItems.map((rankItem, index) => (
                                          <Dropdown.Item key={index} onClick={() => handleRankSelect(rankItem)}>
                                            {rankItem.name}
                                          </Dropdown.Item>
                                        ))}
                                      </Dropdown.Menu>
                                    </Dropdown>

                                    {selectedRankItems.length > 0 && (
                                      <div className="selected-rank-container">
                                        {selectedRankItems.map((item) => (
                                          <div key={item.id} className="selected-rank">
                                            <p className="selected-rank-text">{item.name}</p>
                                            <LazyLoadImage
                                              src="/static/crew/assets/img/icons/icn_close.svg"
                                              alt="Close Icon"
                                              onClick={() => handleRankRemove(item.id)}
                                            />
                                          </div>
                                        ))}
                                      </div>
                                    )}
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
                                  <p className="filter-rank">Vessel Type</p>
                                  <Dropdown className='vessel-search-dropdown'>
                                    <Dropdown.Toggle className="btn dropdown-toggle dropdown-form-button vessel-search" variant="none" id="vessel-dropdown">
                                      <InputGroup className="mb-3 search-rank-input">
                                        <FormControl
                                          className="search-form-input"
                                          placeholder= 'Select Vessels'
                                          aria-label="Search"
                                          aria-describedby="basic-addon2"
                                          value={searchVesselQuery}
                                          onChange={(e) => setSearchVesselQuery(e.target.value)}
                                          required={selectedVesselItems.length === 0}
                                        />
                                      </InputGroup>
                                    </Dropdown.Toggle>
                                      <Dropdown.Menu>
                                        {Object.keys(filteredVesselItems).map((parentKey, parentIndex) => (
                                          <React.Fragment key={parentIndex}>
                                            <Dropdown.Item disabled className='vessle-type-parent'>
                                              <b>{parentKey}</b>
                                            </Dropdown.Item>
                                            {filteredVesselItems[parentKey].map((childItem, childIndex) => (
                                              <Dropdown.Item key={childIndex} onClick={() => handleVesselSelect(childItem)}>
                                                {childItem.name}
                                              </Dropdown.Item>
                                            ))}
                                          </React.Fragment>
                                        ))}
                                      </Dropdown.Menu>
                                    </Dropdown>
                                    {selectedVesselItems.length > 0 && (
                                      <div className="selected-rank-container">
                                        {selectedVesselItems.map((item) => (
                                          <div key={item.id} className="selected-rank">
                                            <p className="selected-rank-text">{item.name}</p>
                                            <LazyLoadImage
                                              src="/static/crew/assets/img/icons/icn_close.svg"
                                              alt="Close Icon"
                                              onClick={() => handleVesselRemove(item.id)}
                                            />
                                          </div>
                                        ))}
                                    </div>
                                  )}
                                </div>
                              </div>
                              <div className="filter-container">
                                <div className="filter-col-header">
                                  <LazyLoadImage src="/static/crew/assets/img/icons/Company_black.svg" alt="Filter Icon" />
                                  <h1 className="heading-filter">Featured Companies</h1>
                                </div>
                                <div className="featured-company-names">
                                  {featuredCompanies.map((company, index) => (
                                    <div key={index} onClick={() => handleCompanyClick(company.id)}>
                                      <h1 className="feature-company-name">{company.company_name}</h1>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            </div>
                          </Col>
                      </Row>
                  </Container>
              </div>

              {jobList && jobList.length !== 0 && (
                <div className="pagination-container">
                  <button className="btn pagination-prev-button"  
                    disabled={!prevPageUrl}
                    onClick={() => handlePageChange(currentPage - 1)}>
                    <LazyLoadImage  src="/static/crew/assets/img/arrows/arrow_right_blue.svg"  />
                  </button>
                  {renderPagination()}
                  <button className="btn pagination-next-button" 
                    disabled={!nextPageUrl}
                    onClick={() => handlePageChange(currentPage + 1)}>
                    <LazyLoadImage  src="/static/crew/assets/img/arrows/arrow_right_blue.svg"  />
                  </button>
                </div>
              )}

            </>

        <Footer />
        <Scripts />
        <EmployerFindJobsScript />
    </>
  );
};

export default EmployerFindJobs