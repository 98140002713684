import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, Button } from 'react-bootstrap';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { apiRoutes } from '../../config/apiConfig';

const JobCardNoAuth = ({ job }) => {
  const [selectedRank, setSelectedRank] = useState(null);
  const [selectedRankId, setSelectedRankId] = useState(null);
  const navigate = useNavigate();

  const handleLoginClick = () => {
    navigate(apiRoutes.login); // Navigate to the login URL
  };

  return (
    <>
      <Card>
        <Card.Body>
          <div className='job-card-cont'>
            <div className="job-card">
              <div className="jobby-info-container">
                <div className="jobby-info">
                  {/* <LazyLoadImage
                    className="profile-img-info"
                    src={job.posted_by.profilePic}
                    alt="Profile"
                  /> */}
                  <div>
                    <h1 className="jobby-name">Job By: {job.posted_by.first_name}</h1>
                    <h1 className="company-name">{job.posted_by.company_name}</h1>
                    <div  style={{ display: 'flex', flexDirection: 'row', gap: '10px', alignItems: 'center' }} onClick={handleLoginClick}>
                        <LazyLoadImage src="/static/crew/assets/img/icons/plus_sign.svg" alt="Plus Sign" />
                        <p className="follow-button">Follow</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="apply-job" style={{ display: 'flex', flexDirection: 'column', gap: '21px' }}>
                <div style={{ display: 'flex', justifyContent: 'flex-end' }} onClick={handleLoginClick}>
                  <LazyLoadImage src="/static/crew/assets/img/icons/Reshare_Forward.svg" alt="Share" />
                  <p className="share-button-name">Share</p>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', gap: '32px' }}>
                  <Button className="like-button btn-primary" type="button" onClick={handleLoginClick}>
                    <LazyLoadImage
                      src='/static/crew/assets/img/icons/Like_heart.svg'
                      alt='Like'
                    />
                    &nbsp; &nbsp;
                    Like
                  </Button>
                    <Button
                      className="apply-button btn-primary"
                      type="button"
                      data-bs-target="#modal-apply-job"
                      data-bs-toggle="modal"
                      onClick={handleLoginClick}
                    >
                      Apply Now
                    </Button>
                </div>
              </div>
            </div>
            <div>
              <div style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
                <h1 className="job-details">Tentative Joining Date:</h1>
                <h1 className="job-details">{job.tentative_joining}</h1>
              </div>
              <div style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
                <h1 className="job-details">Vessel Type:</h1>
                <h1 className="job-details">{job.vessel_id.name}</h1>
              </div>
              <div style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
                <h1 className="job-details">GRT:</h1>
                <h1 className="job-details">{job.GRT}</h1>
              </div>
            </div>
            <div className="job-wages-container">
              <div className="job-wages-container-1">
                {job.Job_Rank_wages.slice(0, Math.ceil(job.Job_Rank_wages.length / 2)).map((wage, index) => (
                  (wage.rank_number && wage.rank_number.name &&
                    <div key={index} className="job-wages">
                      <input
                        type="radio"
                        name={`rank-${job.id}`}
                        // checked={selectedRank === wage.rank_number.name}
                      />
                      <LazyLoadImage src="/static/crew/assets/img/icons/job_bag.svg" alt="Job Bag" />
                      <p className="job-wages-name">{wage.rank_number.name} - {wage.wages}</p>
                    </div>
                  )
                ))}
              </div>
              {job.Job_Rank_wages.slice(Math.ceil(job.Job_Rank_wages.length / 2)).length > 0 && (
                <div className="job-wages-container-1">
                  {job.Job_Rank_wages.slice(Math.floor(job.Job_Rank_wages.length / 2)).map((wage, index) => (
                    (wage.rank_number && wage.rank_number.name &&
                    <div key={index + Math.floor(job.Job_Rank_wages.length / 2)} className="job-wages">
                      <input
                        type="radio"
                        name={`rank-${job.id}`}
                        // checked={selectedRank === wage.rank_number.name}
                      />
                      <LazyLoadImage src="/static/crew/assets/img/icons/job_bag.svg" alt="Job Bag" />
                      <p className="job-wages-name">{wage.rank_number.name} - {wage.wages}</p>
                    </div>
                    )
                  ))}
                </div>
              )}
            </div>
              <div className="requirement-container">
                  {job.job_coc && job.job_coc.length > 0 && (
                      <div className="requirement-container-both">
                        <h1 className="requirement-heading">COC Requirement:</h1>
                        <div className="requirement-value">
                            {job.job_coc.map((coc_names, index) => (
                                <React.Fragment key={index}>
                                    <h1 className="requirement-heading">{coc_names.coc_id.name}</h1>
                                    {index !== job.job_coc.length - 1 && <span>|</span>}
                                </React.Fragment>
                            ))}
                        </div>
                      </div>
                  )}
                  {job.job_cop && job.job_cop.length > 0 && (
                    <div className="requirement-container-both">
                        <h1 className="requirement-heading">COP Requirement:</h1>
                        <div className="requirement-value">
                            {job.job_cop.map((cop_names, index) => (
                                <React.Fragment key={index}>
                                    <h1 className="requirement-heading">{cop_names.cop_id.name}</h1>
                                    {index !== job.job_cop.length - 1 && <span>|</span>}
                                </React.Fragment>
                            ))}
                        </div>
                    </div>
                  )}
              </div>

              <div className="requirement-container">
                  {job.job_Watch_Keeping && job.job_Watch_Keeping.length > 0 && (
                    <div className="requirement-container-both">
                      <h1 className="requirement-heading">Watch-Keeping Requirement:</h1>
                        <div className="requirement-value">
                            {job.job_Watch_Keeping.map((coc_names, index) => (
                                <React.Fragment key={index}>
                                    <h1 className="requirement-heading">{coc_names.Watch_Keeping_id.name}</h1>
                                    {index !== job.job_Watch_Keeping.length - 1 && <span>|</span>}
                                </React.Fragment>
                            ))}
                        </div>
                    </div>
                  )}
                  <div className="requirement-container-both">
                  { job.mail_info && 
                    <h1 className="requirement-heading">Email: {job.posted_by.email}</h1>
                  }
                  { job.number_info && 
                    <h1 className="requirement-heading">Mobile: {job.posted_by.number}</h1>
                  }
                  </div>
              </div>
          </div>
        </Card.Body>
      </Card>
    </>
  );
};

export default JobCardNoAuth;
