import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, Button } from 'react-bootstrap';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { apiRoutes } from '../../config/apiConfig';

const CrewJobCardNoAuth = ({ job }) => {
  const [selectedRank, setSelectedRank] = useState(null);
  const [selectedRankId, setSelectedRankId] = useState(null);
  const navigate = useNavigate();
  const handleLoginClick = () => {
    navigate(apiRoutes.login);
  };

  return (
    <>
      <Card>
        <Card.Body>
          <div className='job-card-cont'>
            <div className="job-card">
              <div className="jobby-info-container">
                <div className="jobby-info">
                  {/* <LazyLoadImage
                    className="profile-img-info"
                    src={job.posted_by.profilePic}
                    alt="Profile"
                  /> */}
                  <div>
                    <h1 className="jobby-name">Job By: {job.posted_by.first_name}</h1>
                    <h1 className="company-name">Referred Job</h1>
                  </div>
                </div>
              </div>
              <div className="apply-job" style={{ display: 'flex', flexDirection: 'column', gap: '21px' }}>
                <div style={{ display: 'flex', justifyContent: 'flex-end' }} onClick={handleLoginClick}>
                  <LazyLoadImage src="/static/crew/assets/img/icons/Reshare_Forward.svg" alt="Share" />
                  <p className="share-button-name">Share</p>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', gap: '32px' }}>
                  <Button className="like-button btn-primary" type="button" onClick={handleLoginClick}>
                    <LazyLoadImage
                      src='/static/crew/assets/img/icons/Like_heart.svg'
                      alt='Like'
                    />
                    &nbsp; &nbsp;
                    Like
                  </Button>
                    <Button
                      className="apply-button btn-primary"
                      type="button"
                      data-bs-target="#modal-apply-job"
                      data-bs-toggle="modal"
                      onClick={handleLoginClick}
                    >
                      Apply Now
                    </Button>

                </div>
              </div>
            </div>
            <div>
              <div style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
                <h1 className="job-details">Tentative Joining Date:</h1>
                <h1 className="job-details">{job.tentative_joining}</h1>
              </div>
              <div style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
                <h1 className="job-details">Vessel Type:</h1>
                <h1 className="job-details">{job.vessel_id.name}</h1>
              </div>
              <div style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
                <h1 className="job-details">Flag:</h1>
                <h1 className="job-details">{ job.flag_key && job.flag_key.country_name}</h1>
              </div>
              <div style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
                <h1 className="job-details">Joining Port:</h1>
                <h1 className="job-details">{job.joining_port}</h1>
              </div>
            </div>
              <div className="requirement-container">
                  {job.job_coc && job.job_coc.length > 0 && (
                      <div className="requirement-container-both">
                        <h1 className="requirement-heading">COC Requirement:</h1>
                        <div className="requirement-value">
                            {job.job_coc.map((coc_names, index) => (
                                <React.Fragment key={index}>
                                    <h1 className="requirement-heading">{coc_names.coc_id.name}</h1>
                                    {index !== job.job_coc.length - 1 && <span>|</span>}
                                </React.Fragment>
                            ))}
                        </div>
                      </div>
                  )}
                  {job.job_cop && job.job_cop.length > 0 && (
                    <div className="requirement-container-both">
                        <h1 className="requirement-heading">COP Requirement:</h1>
                        <div className="requirement-value">
                            {job.job_cop.map((cop_names, index) => (
                                <React.Fragment key={index}>
                                    <h1 className="requirement-heading">{cop_names.cop_id.name}</h1>
                                    {index !== job.job_cop.length - 1 && <span>|</span>}
                                </React.Fragment>
                            ))}
                        </div>
                    </div>
                  )}
              </div>

              <div className="requirement-container">
                  {job.job_Watch_Keeping && job.job_Watch_Keeping.length > 0 && (
                    <div className="requirement-container-both">
                      <h1 className="requirement-heading">Watch-Keeping Requirement:</h1>
                        <div className="requirement-value">
                            {job.job_Watch_Keeping.map((coc_names, index) => (
                                <React.Fragment key={index}>
                                    <h1 className="requirement-heading">{coc_names.Watch_Keeping_id.name}</h1>
                                    {index !== job.job_Watch_Keeping.length - 1 && <span>|</span>}
                                </React.Fragment>
                            ))}
                        </div>
                    </div>
                  )}
              </div>
          </div>
        </Card.Body>
      </Card>
      {/* <ApplyJobModal show={showModal} handleClose={handleCloseModal} handleConfirmApply={handleConfirmApply} /> */}
      {/* <AppliedSuccessModal show={showSuccessModal} handleClose={() => setShowSuccessModal(false)} /> */}
    </>
  );
};

export default CrewJobCardNoAuth;
